<template>
  <div class="container look"
       :class="$mq"
       v-if="look">
    <div class="row">
      <div class="col-md-6">
        <div class="panel" style="margin-bottom: 20px; padding: 20px;">
          <img :src="look.previewUrl" style="width: 100%">
        </div>
        <base-button class="add-to-cart">Add to Cart</base-button>
      </div>
      <div class="col-md-6">
        <look-clothing-items :look="look" />
      </div>
    </div>
  </div>
</template>

<script>
import lookService from '@/services/queries/lookQueries'
import profileService from '@/services/queries/profileQueries'
import LookClothingItems from './LookClothingItems.vue'
import hasBackgroundMixin from '@/services/mixins/hasBackgroundMixin.js'
import LookActions from '@/components/Shared/LookActions.vue'

export default {
  components: {
    LookActions,
    LookClothingItems
  },

  data( ) {
    return {
      look: null,
      lookAuthor: null
    }
  },

  mixins: [hasBackgroundMixin],

  metaInfo () {
    return {
      title: this.look?.title || '',

      meta: [
        {
          'vmid': 'og:title',
          'property': 'og:title',
          'content': this.look?.title || '',
        },
        {
          'vmid': 'og:description',
          'property': 'og:description',
          'content': this.look?.description || '',
        },
        {
          'vmid': 'og:image',
          'property': 'og:image',
          'content': this.look?.previewUrl || ''
        }
      ]
    }
  },

  beforeRouteEnter (to, from, next) {
    lookService.find(
      to.params.userId,
      to.params.lookId
    ).then(look => {
      profileService.find(look.authorId).then(author => {
        next(vm => {
          vm.look = look
          vm.lookAuthor = author
        })
      })
    })
  }
}
</script>

<style lang="scss" scoped>
  .look {
    display: flex;
    margin-top: 40px;
  }
</style>
