<template>
  <div class="look-actions">
    <div class="action"
         @mouseover="isLikeIconHovered = true"
         @mouseleave="isLikeIconHovered = false"
         @click="onLike">
      <base-icon-like :active="liked || isLikeIconHovered"/>
      <div class="title">
        {{ likesNumber }}
      </div>
    </div>

    <div class="action"
         ref="cart"
         @click="toCart">
      <base-icon class="icon" icon="cart"></base-icon>
      <div class="title">
        {{ $t('lookFeed.cart.toCart') }}
      </div>
    </div>

    <div class="action"
         @click="goToStudio(look)">
      <base-icon icon="studio"></base-icon>
      <div class="title">
        {{ $t('lookFeed.toStudio') }}
      </div>
    </div>

    <div class="action"
         ref="socialSharing">
      <base-icon icon="share"></base-icon>
      <div class="title">
        {{ $t('lookFeed.share') }}
      </div>

      <base-look-share :look="look"
                       ref="socialSharingDropdown"/>
    </div>

    <span ref="addToCartResultMessage">
      {{ addToCartResultText }}
    </span>
  </div>
</template>

<script>
  import { authenticated } from '@/services/auth'
  import lookService from '@/services/queries/lookQueries'

  export default {
    props: {
      look: {
        required: true,
        type: Object
      }
    },

    data() {
      return {
        liked: this.look.liked,
        addToCartResultText: '',
        isLikeIconHovered: false,
        likesNumber: this.look.likesNumber
      }
    },

    mounted() {
      this.initSocialSharingDropdown()
      this.initCartPopup()
    },

    methods: {
      onLike() {
        if (!authenticated()) {
          this.openAuthModal({ content: 'login' })
          return
        }

        if (!this.liked) {
          this.likesNumber++
          lookService.like(this.look.authorId, this.look.id)
        } else {
          this.likesNumber--
          lookService.removeLike(this.look.authorId, this.look.id)
        }

        this.liked = !this.liked
      },

      toCart() {
        if (!this.look.clothingItems.length) {
          this.showAddToCartTooltip('nothingToAddToCart')

          return
        }

        if (this.look.clothingItems.every(item => this.isItemInCart(item))) {
          this.showAddToCartTooltip('alreadyInCart')

          return
        }

        this.addToCart(this.look)
        this.showAddToCartTooltip('addedToCart')
      },

      showAddToCartTooltip(messageType) {
        this.addToCartResultText = this.$t(`lookFeed.cart.${messageType}`)

        this.$nextTick(() => {
          this.$refs.cart._tippy.show()
        })
      },

      goToStudio() {
        this.clearLook()

        let lookCopy = _.cloneDeep(this.lookInState)
        lookCopy.items = this.look.items.map((item) => {
          return {
            ...item,
            clothingItemType: this.constants$.artboardPictureTypes.existingClothing,
            imageUrl: this.getImageUrl(item.clothing_item)
          }
        })

        this.setLook(lookCopy)

        this.$router.push({ name: 'studio' })
      },

      initSocialSharingDropdown() {
        tippy(this.$refs.socialSharing, {
          content: this.$refs.socialSharingDropdown.$el,
          interactive: true,
          theme: 'light dropdown-menu share',
          animation: 'shift-away',
          placement: 'bottom',
          arrow: true,
          duration: [600, 300],
          inertia: true
        })
      },

      initCartPopup() {
        tippy(this.$refs.cart, {
          content: this.$refs.addToCartResultMessage,
          animation: 'shift-away',
          placement: 'bottom',
          arrow: true,
          trigger: 'manual',
          onShown(tooltip) {
            setTimeout(() => {
              tooltip.hide()
            }, 800)
          },
        })
      },

      // TODO: Remove, use transformed data instead
      getImageUrl(good) {
        return good.images && good.images.length > 0 ?
            good.images[0].slices_info.filter((info) => {
              return info.title === 'middle'
            })[0].url : null
      },

      ...mapActions('cart', {
        addToCart: 'addLook'
      }),

      ...mapActions('studio', [
        'clearLook',
        'setLook'
      ]),

      ...mapActions('system', [
        'openAuthModal'
      ])
    },

    computed: {
      ...mapGetters('studio', {
        lookInState: 'getLook'
      }),

      ...mapGetters('cart', [
        'isItemInCart'
      ])
    }
  }
</script>

<style lang="scss">


  .look-actions {
    display: flex;
    justify-content: center;
    width: 452px;
    height: 90px;
    flex-basis: 90px;
    margin-top: auto;
    border-top: 1px solid #F4F4F4;

    .action {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      width: 113px;
      height: 100%;
      cursor: pointer;

      & > .title {
        transition: all 0.3s;
      }

      &:hover {
        .title {
          color: $primary-color;
        }
      }

      .title {
        font-size: 14px;
        color: #797979;
        line-height: 23px;
        margin-top: 8px;
      }
    }
  }
</style>
