<template>
  <div class="list" :class="$mq">
    <clothing-item-card v-for="clothingItem in look.clothingItems"
                        :key="clothingItem.id"
                        :clothing-item="clothingItem"
                        class="clothing-item"/>
  </div>
</template>

<script>
  import ClothingItemCard from '@/components/Shared/ClothingItemCard/ClothingItemCard.vue'

  export default {
    props: {
      look: {
        required: true,
        type: Object
      }
    },

    components: {
      ClothingItemCard
    }
  }
</script>

<style lang="scss" scoped>
  .list {
    display: flex;
    flex-wrap: wrap;

    .clothing-item {
      flex: 50%;
      max-width: 50%;
      padding: 10px;
    }
  }
</style>
